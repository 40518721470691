import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const OurHistory = () => {
    return (
        <section className="history-area ptb-100 bg-e7edf9">
            <div className="container">
                <div className="section-title">
                    <h2>Историјата започна во 2016</h2>
                </div>

                <ol className="timeline history-timeline">
                    <li className="timeline-block">
                        <div className="timeline-date">
                            <span>2016</span>
                        </div>

                        <div className="timeline-icon">
                            <span className="dot-badge"></span>
                        </div>

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Идејата беше родена</h3>
                                        <p>Tafoma - нашиот нов софтвер за дигитална работна средина: креиран од нашата заедничка потреба за јузер френдли работна средина, каде што соработката е клучен фактор.</p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <StaticImage src={'../../assets/images/about/history1.png'} 
                                        alt="Person next to lightbulb." 
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-block">
                        <div className="timeline-date">
                            <span>2018</span>
                        </div>

                        <div className="timeline-icon">
                            <span className="dot-badge"></span>
                        </div>

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Пуштена е бета верзијата</h3>
                                        <p>Бевме спремни да ја покажеме нашата сè-во-едно, рационализирана платформа на јавноста. Сакавме сите да искусат како нашето неинтегративно софтверско решение ќе го револуционизира нивниот тим и нивниот проектен менаџмент.</p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <StaticImage src={'../../assets/images/about/history2.png'} 
                                        alt="Two people siting on a cloud." 
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-block">
                        <div className="timeline-date">
                            <span>2020</span>
                        </div>

                        <div className="timeline-icon">
                            <span className="dot-badge"></span>
                        </div>

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Пуштена е Tafoma 1.0 верзијата</h3>
                                        <p>Опремени со позитивни критики од крајните корисници, работевме напорно за да го прилагодиме и развиеме софтверот, и конечно можевме да излеземе од бета верзијата. Со Tafoma создадовме единствена, ефикасна веб-базирана алатка која ги обединува сите потреби на работното место; елегантно дизајнирано дигитално решение кое ја олеснува продуктивноста и ефикасноста. Сè што ви треба за следење на проекти, менаџирање и соработка во рамките на една платформа, без да барате интеграција од трета страна: заљубете се во еволуцијата на дигиталното работно место со Tafoma.</p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                    <StaticImage src={'../../assets/images/about/history3.png'} 
                                        alt="Person next to phone." 
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
        </section>
    )
}

export default OurHistory