import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <h2>Цел, Мисија и Визија</h2>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    {/* <h3>Data for All Your People</h3> */}
                                    <p><span className="black-text">Цел</span> - прифаќање на транзицијата на индустријата на работна околина базирана на cloud - облак, подобрување на искуството на вработените и работни средини што се доказ за иднината.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    {/* <h3>A New Breed of AI</h3> */}
                                    <p><span className="black-text">Мисија</span> - следење на најновите трендови во технологијата, со цел стратешко инвестирање во истражување и развој, како и постојано усовршување на сите наши вработени.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    {/* <h3>Analytics Business</h3> */}
                                    <p><span className="black-text">Визија</span> - проширување на глобално ниво, со главен фокус на континуирано подобрување на нашите услуги, како и раст и развој на компанијата – заедно со нејзините партнери, вработени и корисници. Сè со цел да станеме познат бренд.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <StaticImage src={'../../assets/images/about/pmv-img.png'} 
                                        alt="Teammates collaborating." 
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs