import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <StaticImage src={'../../assets/images/about/main.png'} 
                                        alt="Group of people standing next to eachother." 
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        placeholder="none"
                                        loading="eager"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>За нас</h2>
                                <p className="text-justify">Tafoma е тим на искусни професионалци водени од иста цел: да ja опфатат новaтa и развиена дигитална работна околина, со сè-во-едно решение за проблемите со тимската работа и управувањето со проекти.</p>
                                
                                <p className="text-justify">Инспирирани од брзо растечките промени на модерниот свет, потребата за транспарентност, барањата за ефикасна и продуктивна тимска работна околина и нашето искуство во областа, ја креиравме Tafoma.</p>

                                {/* <p className="text-justify">Now, we bring you our vision. <span className="black-text">Let us reinvent the working environment with you! </span></p> */}
                                <p className="text-justify">Сега, ви ја претставуваме нашата визија. <span className="black-text">Ајде да ја реинвентираме работната околина заедно!</span></p>
                                <Link to="/blog/who-is-behind-tafoma-meet-the-team" className="default-btn">
                                Повеќе за нас<span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUsContent;